// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-app-tsx": () => import("./../../../src/pages/app.tsx" /* webpackChunkName: "component---src-pages-app-tsx" */),
  "component---src-pages-archive-scroll-tsx": () => import("./../../../src/pages/archive_scroll.tsx" /* webpackChunkName: "component---src-pages-archive-scroll-tsx" */),
  "component---src-pages-archive-tsx": () => import("./../../../src/pages/archive.tsx" /* webpackChunkName: "component---src-pages-archive-tsx" */),
  "component---src-pages-faq-tsx": () => import("./../../../src/pages/faq.tsx" /* webpackChunkName: "component---src-pages-faq-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-preview-tsx": () => import("./../../../src/pages/preview.tsx" /* webpackChunkName: "component---src-pages-preview-tsx" */),
  "component---src-pages-privacy-policy-tsx": () => import("./../../../src/pages/privacy-policy.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-tsx" */),
  "component---src-pages-redeem-signup-tsx": () => import("./../../../src/pages/redeem-signup.tsx" /* webpackChunkName: "component---src-pages-redeem-signup-tsx" */),
  "component---src-pages-redeem-tsx": () => import("./../../../src/pages/redeem.tsx" /* webpackChunkName: "component---src-pages-redeem-tsx" */),
  "component---src-pages-referral-tsx": () => import("./../../../src/pages/referral.tsx" /* webpackChunkName: "component---src-pages-referral-tsx" */),
  "component---src-pages-terms-tsx": () => import("./../../../src/pages/terms.tsx" /* webpackChunkName: "component---src-pages-terms-tsx" */),
  "component---src-pages-thesetup-tsx": () => import("./../../../src/pages/thesetup.tsx" /* webpackChunkName: "component---src-pages-thesetup-tsx" */),
  "component---src-pages-waitlist-tsx": () => import("./../../../src/pages/waitlist.tsx" /* webpackChunkName: "component---src-pages-waitlist-tsx" */),
  "component---src-pages-warranty-returns-tsx": () => import("./../../../src/pages/warranty-returns.tsx" /* webpackChunkName: "component---src-pages-warranty-returns-tsx" */),
  "component---src-templates-event-post-tsx": () => import("./../../../src/templates/eventPost.tsx" /* webpackChunkName: "component---src-templates-event-post-tsx" */)
}

